body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#App {
  min-height: 100vh;
  width: 100%;
  margin: 0;
}


.title {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 250px;
  max-width: 25%;
  background: #b34248; 
  opacity: 0.95;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.title h1 {
  padding: 0px 60px;
  text-align: center;
  border-radius: 7px;
  color: #F0F8FF;
}

.title button {
  padding: 10px;
  margin: 5px;
  font-size: 21px;
  color: #FCFAF9;
  background: #4B666B;
  border: 0;
  border-radius: 7px;
  cursor: pointer;
}

.resume button{
  padding: 10px;
  margin: 5px;
  font-size: 21px;
  color: #FCFAF9;
  
  background: #4B666B;
  border: 0;
  border-radius: 7px;
  cursor: pointer;
}

.git {
  padding: 20px;
  margin-bottom: 5px;
}

@media screen and (max-width: 500px) {
  .title {
    background: none;
  }
  .git {
    background: #7EA8BE;
    border-radius: 7px;
  }
}

